module.exports = {
    APP_URL: "https://exclusifcar.com/",
    BACKEND_URL: "https://api.exclusifcar.com/public/index.php/api/",
    STORAGE_URL: "https://api.exclusifcar.com/public/storage/"
}



// module.exports = {
//     APP_URL: "http://localhost:3001/",
//     BACKEND_URL: "http://localhost:8000/api/",
//     STORAGE_URL: "http://localhost:8000/storage/"
// }