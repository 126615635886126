import Cars from "./components/Cars";
import Home from "./components/Home";
import Contact from "./components/Contact";
import Services from "./components/Services";
import Header from "./components/Layout/Header";
import Footer from "./components/Layout/Footer";
import Order from "./components/Order";
import Authentication from "./components/Authentication";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AWN from "awesome-notifications"
import i18n from "i18next";
import FR from './local/fr.json'
import EN from './local/en.json'

import { useTranslation, initReactI18next } from "react-i18next";
import { useEffect } from "react";


i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: EN,
      fr: {
        "header": {
            "home": "Acceuil",
            "cars":"voitures"
        },
        "home-page": {
            "make-trip": {
                "title":"Faites votre voyage",
                "pick-up-location": "Lieu de récuperation",
                "drop-off-location":"Lieu de depot",
                "pick-up-time": "Date de récuperation",
                "drop-off-time":"Date de depot",
                "rent-car": "Réserver maintenant"
            }
        }
    }
    },
    lng: "fr", // if you're using a language detector, do not define the lng option
    fallbackLng: "fr",

    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    }
  });


function App() {
    const  notifier = new AWN({
        durations: 500,
        animationDuration: 500,
        position: "top-right"
    })
    const { t } = useTranslation();

    useEffect(()=> {
        i18n.changeLanguage('fr')

    }, [])



return (
        <div className="App">
            <Header  />
            <Routes>
                <Route path=""  element={<Home notifier={notifier} />} />
                <Route path="services"  element={<Services notifier={notifier} />} />
                <Route path="cars"  element={<Cars notifier={notifier} />} />
                <Route path="contact"  element={<Contact notifier={notifier} />} />
                <Route path="book"  element={<Order notifier={notifier} />} />
                <Route path="authentication"  element={<Authentication notifier={notifier} />} />
                {/*<Route path="step-3" element={<StepThree />}/>
                   <Route path="step-4" element={<StepFour />}/> */}
            </Routes>
            <Footer />

        </div>
    );

}

export default App;