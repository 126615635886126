import React, { useEffect, useState } from 'react'
import { Link, useParams } from "react-router-dom";
import { APP_URL } from '../../config';
import logo from "../../images/logo.png";
import { Lang } from '../Lang';
 

/**
 * 
 * @returns 
 */
export default function Header() {
 

    const [userDetails, setUserDetails] = useState({});
    const [connected, setConnected] = useState(false);
    const [activeRoute, setActiveRoute] = useState('/home');

    useEffect(() => {
        setActiveRoute(window.location.pathname)
         let local_item = JSON.parse(localStorage.getItem("excl_user"));
         console.log('getted item', local_item)
        if (local_item?.length == 0 || local_item == undefined) {

        }
        else {
            setUserDetails(local_item[0]);
            setConnected(true);
        }
    }, []);

 
    /**
     * 
     * @param {*} e 
     */
    const logout = (e) => {
        if (e.target.value == 2) {
            console.log("logout");
            localStorage.removeItem("excl_user");
            window.location.reload();

        }
    }
    useEffect(()=> {
        console.log("active route is", activeRoute)
    }, [activeRoute])


    return (<div>
        <nav className="navbar navbar-expand-lg navbar-dark ftco_navbar bg-dark ftco-navbar-light" id="ftco-navbar">
            <div className="container">
                <a className="navbar-brand" href={APP_URL}> <img style={{ "width": "150px" }} src={logo} className="img-fluid" alt="" />
                </a>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#ftco-nav" aria-controls="ftco-nav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="oi oi-menu"></span> Menu
                </button>

                <div className="collapse navbar-collapse" id="ftco-nav">
                    <ul className="navbar-nav ml-auto">
                        <li className={`nav-item ${window.location.pathname == ('/home' || '/') ? "active" : 's'}`}><a href={`${APP_URL}`} className="nav-link">{"home"}</a></li>
                        <li className={`nav-item ${activeRoute == '/services' ?? "active"}`}><a href={`${APP_URL}services`} className="nav-link">Services</a></li>
                        <li className={`nav-item ${activeRoute == '/cars' ?? "active"}`}><a href={`${APP_URL}cars`} className="nav-link">{'cars'}</a></li>
                        <li className={`nav-item ${activeRoute == '/contact' ?? "active"}`}><a href={`${APP_URL}contact`} className="nav-link">Contact</a></li>
                       
                        {connected && <li className="nav-item">
                            <select
                                onChange={logout}
                                style={{ "border": "none" }}
                                name=""
                                className='nav-link bg-transparent'
                                id="">
                                <option style={{ "color": "#ce9532" }}>
                                    {userDetails.email}
                                </option>
                                <option

                                    value="2">
                                    logout
                                </option>
                            </select>
                        </li>}
                        {!connected &&
                            <li className="nav-item">
                                <a href={`${APP_URL}authentication`}
                                    className='nav-link bg-transparent'>login/register
                                </a> </li>}
                        <li className="nav-item">
                       <Lang />
                            
                            </li>

                    </ul>
                </div>
            </div>
        </nav>
    </div>
    )
}