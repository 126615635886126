import { React, useState } from "react";
import { APP_URL } from "../../config";





export default function PaymentMethod(props) {

    const [customerPaymentMethod, setCustomerPaymentMethod] = useState([false, false, true]);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("Westen Union");

    const notifier = props.notifier;






/**
 * 
 * @param {*} e 
 */

    const handlePaymentMethodClick = (e) => {
        console.log("now :", e.target.id);
        let tmp = [...customerPaymentMethod];
        switch (e.target.id) {
            case "1":
                tmp.fill(false);
                tmp[0] = true;
                setSelectedPaymentMethod("Bank transfert");
                notifier.info("Payment method now is <strong>Bank transfert</strong>")
                break;
            case "2":
                tmp.fill(false);
                tmp[1] = true;
                setSelectedPaymentMethod("Pay at pick");
                notifier.info("Payment method now is <strong>Pay at pick</strong>")
                break;
            case "3":
                tmp.fill(false);
                tmp[2] = true;
                setSelectedPaymentMethod("Western union");
                notifier.info("Payment method now is <strong> Western union</strong>")
                break;
        }

        setCustomerPaymentMethod(tmp);
    }

    /**
     * 
     */
    const handleContinue = () => {

        let old = JSON.parse(localStorage.getItem("excl_rental_order"));

        localStorage.setItem("excl_rental_order",JSON.stringify({
            ...old,
            "selected_payment_method":selectedPaymentMethod
        }));

        setTimeout(() => {
            window.location.replace(`${APP_URL}book?step=5`);
        }, 1000);
    }


    return (
        <div className="container">
            <div className="row">
                <h2 className="text-center col-sm-12">
                    <span>payement Method</span>
                </h2>
                <div className="alert alert-info col-sm-12">
                    <span>Select the payment that you want to send to use your <strong>Deposit</strong></span>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12 row text-center">
                    <div className="card col-lg-4   mx-auto">
                        <h5 className="card-header">
                            <span>Direct Bank Transfer </span>
                            {customerPaymentMethod[0] && <i className="far fa-check-circle text-success"></i>}
                        </h5>

                        <div className="card-body row">
                            <table className="  col-sm-12 ">
                                <tr >
                                    <td><strong >Company :</strong> EXCLUSIF CAR</td>
                                </tr>
                                <tr>
                                    <td><strong>Bank :</strong> ATTIJARIWAFA BANK</td>
                                </tr>
                                <tr>
                                    <td><strong>IBAN :</strong> EXCLUSIF CAR</td>
                                </tr>
                                <tr>
                                    <td><strong>ROUTING :</strong> EXCLUSIF CAR</td>
                                </tr>
                                <tr>
                                    <small className="text-danger">* Payment method availabe of only more than <strong>14</strong> days rental</small>
                                </tr>
                            </table>
                            <div className="col-sm-12 text-center">
                                <button
                                    id="1"
                                    onClick={handlePaymentMethodClick}
                                    className="btn btn-primary mt-1">
                                    set as my payment method</button>

                            </div>
                        </div>
                    </div>
                    <div className="card col-lg-4 mx-auto">
                        <h5 className="card-header"><span>
                            Pay at Pick-up
                        </span>
                            {customerPaymentMethod[1] && <i className="far fa-check-circle text-success"></i>}
                        </h5>
                        <div className="card-body row">
                            <div className="col-sm-12">
                                <div className="alert alert-info">
                                    Cash Payment Only

                                </div>
                                <p>A prepayment of 3 days is required to confirm your reservation otherwise it will be canceled automatically in 48h.</p>
                            </div>
                            <div className="col-sm-12 text-center">
                                <button
                                    id="2"
                                    onClick={handlePaymentMethodClick}
                                    className="btn btn-primary mt-1">
                                    set as my payment method</button>

                            </div>
                        </div>
                    </div>
                    <div className="card col-lg-4 mx-auto">
                        <h5 className="card-header"><span>Western Union </span>
                            {customerPaymentMethod[2] && <i className="far fa-check-circle text-success"></i>}
                        </h5>
                        <div className="card-body row">
                            <table className="  col-sm-12 ">
                                <tr >
                                    <td><strong >Full Name :</strong> EXCLUSIF CAR</td>
                                </tr>
                                <tr>
                                    <td><strong>NID :</strong> ATTIJARIWAFA BANK</td>
                                </tr>
                                <tr>
                                    <td><strong>Country :</strong> EXCLUSIF CAR</td>
                                </tr>
                                <tr>
                                    <td><strong>ROUTING :</strong> EXCLUSIF CAR</td>
                                </tr>
                                <tr>
                                    <h6 className="text-danger">
                                        * Please send us MTCN number to
                                        <strong> {`<payment@exclusifcar.com>`} </strong>
                                        in order to confirm your reservation.</h6>
                                </tr>
                            </table>
                            <div className="col-sm-12 text-center">
                                <button
                                    id="3"
                                    onClick={handlePaymentMethodClick}
                                    className="btn btn-primary mt-1">
                                    set as my payment method</button>


                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div className="row mt-5 mb-5">
                <div className="col-sm-12 text-center">
                    <button
                    onClick={handleContinue}
                        className="btn btn-success">
                        Continue to the next step
                    </button>
                </div>
            </div>
        </div>
    )
}