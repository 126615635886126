import { React, useState, useEffect } from "react";
import { STORAGE_URL } from "../../config";

import TimeLine from "../Layout/TimeLine";
import moment from "moment";
import banner from "../../images/banner_4.jpeg"

export default function OrderRecap(props) {


  const [selectedCars, setSelectedCars] = useState([]);
  const [pickupTime, setPickupTime] = useState();
  const [duration, setDuration] = useState(3);
  const [dropTime, setDropTime] = useState();

  const [pickupLocation, setPickupLocation] = useState(null);
  const [dropLocation, setDropLocation] = useState(null);
  const [rentalPrices, setRentalPrices] = useState([]);


  /**
   * 
   * @param {*} order 
   */
  const getDateLocationCarInformations = (order) => {

    if (order.selectedCars) setSelectedCars(order.selectedCars);
    if (order.dropTime) setDropTime(order.dropTime);
    if (order.pickupTime) setPickupTime(order.pickupTime);
    if (order.duration) setDuration(order.duration);
    if (order.dropLocation) setDropLocation(order.dropLocation);
    if (order.pickupLocation) setPickupLocation(order.pickupLocation);


  }

  const computeRentalPrice = (order) => {
    let tmp = [...rentalPrices];
    if (order.duration <= 10) {
      order.selectedCars.map((c, index) => {
        console.log("car", c);
        tmp.push({ id: c[0].id, price: c[0].price_per_day_1_10 });
      })
      setRentalPrices(tmp);

      console.log("rental prices are", tmp);
    }
    else {
      order.selectedCars.map((c, index) => {
        tmp.push({ id: c[0].id, price: c[0].price_per_day_10_20 });
      })
      setRentalPrices(tmp);

      console.log("rental prices are", tmp);
    }
  }



  useEffect(() => {
    let order = JSON.parse(localStorage.getItem("excl_rental_order"));
    getDateLocationCarInformations(order);
    if (props.step >= 3) {
      computeRentalPrice(order);

    }
  }, []);

  return (
    <div>

      {props.step < 5 &&
        <div className="container mt-5">
          <h2 className="text-center text-muted"> Booking Process</h2>
          <hr />
          <div className="shadow p-3 mb-5 bg-white rounded">
            <TimeLine
              step={props.step}
            />
          </div>
          <h2 className="text-center text-muted">
            Selected Times And Locations
          </h2>
          <hr />
          {props.step >= 2 &&
            <div className="row  shadow p-3 mb-5 bg-white rounded text-center">
              <div className="row text-center ">
                <div className="card col-lg-12 mt-1">
                  <h5 className="card-header bg-primary text-white">Period</h5>
                  <div className="card-body row">
                    <div className="col-lg-12">
                      <i className="fas fa-calendar-week"></i>
                      <small className="ml-1">{moment(pickupTime).format('MMMM Do YYYY, h:mm:ss a')}</small>
                    </div>
                    <div className="col-lg-12">
                      <i className="fas fa-calendar-week"></i>
                      <small className="ml-1">{moment(dropTime).format('MMMM Do YYYY, h:mm:ss a')}</small>
                    </div>
                    <div className="col-lg-12">
                      {/* <i className="far fa-money-bill-alt"></i> */}
                      <i className="far fa-clock"></i>
                      <small className="ml-1">{duration} days</small>
                    </div>
                  </div>
                </div>
                <div className="card col-lg-12 ml-1 mt-1">
                  <h5 className="card-header bg-primary text-white">Pickup</h5>
                  <div className="card-body">
                    <div className="col-lg-12">
                      <i className="fas fa-compass"></i>
                      <small className="ml-1">{pickupLocation?.name}</small>
                    </div>
                    <div className="col-lg-12">
                      <i className="far fa-money-bill-alt"></i>
                      <small className="ml-1">{pickupLocation?.fees}€</small>
                    </div>

                  </div>
                </div>
                <div className="card col-lg-12 ml-1 mt-1">
                  <h5 className="card-header bg-primary text-white">Drop off</h5>
                  <div className="card-body">
                    <div className="col-lg-12">
                      <i className="fas fa-compass"></i>
                      <small className="ml-1">{dropLocation?.name}</small>
                    </div>
                    <div className="col-lg-12">

                      <i className="far fa-money-bill-alt"></i>
                      <small className="ml-1">{dropLocation?.fees}$</small>
                    </div>

                  </div>
                </div>

              </div>
            </div>}


          {props.step >= 3 && <div className="row">
            <h2 className="text-center text-muted"> Selected Cars</h2>
            <div className="alert alert-warning">
              <p>
                Prices are computed based on
                <strong>Pickup location, </strong>
                <strong>Drop location,</strong>
                <strong>And the number of days that you want the cars</strong>
              </p>
            </div>
            <hr />
            {
              selectedCars.map((car) => (
                <div className="card col-lg-12 shadow p-3 mb-5 bg-white rounded">
                  <div className="row no-gutters">
                    <div className="col-auto">
                      <img
                        style={{ "height": "300px" }}
                        src={`${STORAGE_URL}${car[0].picture}`}
                        className="img-fluid" alt="" />
                    </div>
                    <div className="col">
                      <div className="card-block px-2">
                        <h4 className="card-title">{car.selected_quantity + " x " + car[0].name}</h4>
                        <p className="card-text">{car[0].company}</p>
                        <table className="table table-striped">

                          <tbody>
                            <tr>
                              <td>Price / day</td>
                              <td><strong>{rentalPrices.filter(ca => ca.id == car[0].id)[0].price} €</strong></td>
                            </tr>
                            <tr>
                              <td>Total</td>
                              <td><strong>{rentalPrices.filter(ca => ca.id == car[0].id)[0].price * duration} €</strong></td>
                            </tr>
                            <tr>
                              <td>Deposit</td>
                              <td><strong>{car[0].deposit} €</strong></td>
                            </tr>
                            <tr>
                              <td>Franchise</td>
                              <td><strong>{car[0].franchise}€</strong></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                </div>
              ))
            }

          </div>}
          <hr />
        </div>
      }

    </div>
  );
}