import axios from "axios";
import { React, useEffect, useState } from "react";
import { APP_URL, BACKEND_URL, STORAGE_URL } from "../../config";


export default function CarList(props) {

    /**
     * states 
     */
    const [cars, setCars] = useState([{
            id: 1,
            model: "evoque",
            company: "range rover",
            available: 3,
            rental_price: 500
        },
        {
            id: 2,
            model: "cla",
            company: "mercedes",
            available: 3,
            rental_price: 500
        }
    ]);
    const [selectedCars, setSelectedCars] = useState([]);
    const [selectedCarsIds, setSelectedCarsIds] = useState([]);

    const notifier = props.notifier;
    /**
     * Effects 
     * @param {*} e 
     */

    useEffect(() => {
        axios.get(`${BACKEND_URL}cars`)
            .then((response) => {
                console.log(response);
                setCars(response.data)
            }).catch((err) => {
                console.log("an error occured", err)
            })
    }, []);
    /**
     * handlers
     * @param {*} e 
     */


    const handleChooseButtonClick = (e) => {
            let choosen_input_value = document.getElementById(`input_${e.target.id}`).value;
            let existing_car = selectedCars.filter(car => car.id == e.target.id);

            console.log(existing_car.length);
            if (existing_car.length == 0) {
                // let matched_car = cars.filter((c) => e.target.id)[0];
                // setSelectedCars([matched_car, ...selectedCars]);
                let target_car = cars.filter(c => c.id == e.target.id);
                setSelectedCars([{...target_car, "selected_quantity": choosen_input_value }, ...selectedCars]);
                setSelectedCarsIds(e.target.id, ...selectedCarsIds);

            } else {
                existing_car[0].quantity = choosen_input_value;
                const index = selectedCars.findIndex(car => car.id === existing_car[0].id);
                selectedCars[index] = existing_car[0];
                setSelectedCars(selectedCars);
                setSelectedCarsIds(selectedCarsIds);
                console.log(selectedCars);
                notifier.info(`Updated quantity of ${existing_car[0].name},Your garage contain now ${selectedCars.map((selected)=> (`${selected.name}, `))}.`)



		}
	}
 
	const handleContinueClick = (e) => {
		console.log("continue");
		localStorage.setItem("excl_rental_order", JSON.stringify({
			selectedCars,
			...JSON.parse(localStorage.getItem("excl_rental_order"))
		}));

		window.location.replace(`${APP_URL}book?step=3`);
	}
	const handleDetailButtonClick = (e) => {

	}

	useEffect(() => {
		console.log(selectedCars);
	}, [selectedCars])


	/**
	 * render
	 */
	return (

		<section className="ftco-section bg-light">
			<h2 className="text-center">Choose your cars</h2>
			<hr />
			<div className="container">
				<div className="row shadow p-3 mb-5 bg-white rounded">
			
					{
						cars.map((car) => (
							<div className="col-md-4">
								<div className="car-wrap rounded ">
									<div className="img rounded d-flex align-items-end" style={{ "background-image": `url(${STORAGE_URL}${car.picture})` }}>
									</div>
									<div className="text">
										<h2 className="mb-0">
											<a href="car-single.html">{car.name}</a></h2>
										<div className="d-flex mb-3">
											<span className="cat" 
											

											>{car.company}</span>
											<p className="price ml-auto">from €{car.price_per_day_1_10} <span>/day</span></p>
										</div>
										<hr />
										<p className="d-flex mb-0 d-block row">
											<div className="row mb-2">
												<label className="col-sm-8" htmlFor="">Number of cars desired</label>
												<input
													type="number"
													defaultValue={1}
													max={car.available}
													min={1}
													id={`input_${car.id}`}
													className="col-sm-3 form-control" />
												<small className="col-sm-12 text-danger">Available <span>{car.quantity}</span> </small>
											</div>
											<div className="col-lg-12 row ml-auto mt-2">
												<button className="btn btn-primary col-lg-5" id={car.id} onClick={handleChooseButtonClick}>choose</button>
												<button onClick={handleDetailButtonClick} id={car.id} className="btn btn-secondary col-lg-5 offset-lg-1">Details</button>


												{selectedCarsIds.includes(car.id) && <button

													onClick={handleContinueClick}
													className="btn btn-success"
													style={{ "margin-top": "10px" }}>
													Continue to the next step</button>}

											</div>
										</p>
									</div>
								</div>
							</div>
							
						))
					}



				</div>
				<div className="col-sm-12 text-center">
				<button						onClick={handleContinueClick}
								className="btn btn-success "
													style={{ "margin-top": "10px" }}>
													Continue to the next step</button>
				</div>
			
			</div>
		</section>
	)
}