import { getThemeProps } from "@mui/system";
import axios from "axios";
import moment from "moment";
import { React, useEffect, useState } from "react";
import { APP_URL, BACKEND_URL, STORAGE_URL } from "../../config";

export default function OrderConfirmation(props) {



    const notifier = props.notifier;
    const [confirmProgress, setConfirmProgress] = useState(false);
    const [selectedCars, setSelectedCars] = useState([]);
    const [pickupTime, setPickupTime] = useState();
    const [duration, setDuration] = useState(3);
    const [dropTime, setDropTime] = useState();
    const [rentalPrice, setRentalPrice] = useState(0);
    const [maxFranchise, setMaxFranchise] = useState(0);
    const [maxDeposit, setMaxDeposit] = useState(0);
    const [pickupLocation, setPickupLocation] = useState({ id: 3, name: "Casablanca - Med V Airport / City Center", created_at: null, updated_at: null });
    const [dropLocation, setDropLocation] = useState({ id: 3, name: "Casablanca - Med V Airport / City Center", created_at: null, updated_at: null });
    const [rentalPrices, setRentalPrices] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [userDetails, setUserDetails] = useState({});
    const [total, setTotal] = useState(0);
    const [totalCars, setTotalCars] = useState(0);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("Western union");
    const [totalOptions, setTotalOptions] = useState(0);

    const getDateLocationCarInformations = (order) => {
        setSelectedCars(order.selectedCars);
        setDropTime(order.dropTime);
        setPickupTime(order.pickupTime);
        setDuration(order.duration);
        setDropLocation(order.dropLocation);
        setPickupLocation(order.pickupLocation);
        setSelectedOptions(order.selectedOptions);
        setSelectedPaymentMethod(order.selected_payment_method);

        console.log(order.selectedCars);

    }
    useEffect(() => {
        let order = JSON.parse(localStorage.getItem("excl_rental_order"));
        let user = JSON.parse(localStorage.getItem("excl_user"));
        setUserDetails(user);
        getDateLocationCarInformations(order);
        computeRentalPrice(order);
    }, [])

    useEffect(() => {
        let maxDeposit = 0;
        let maxFranchise = 0;
        selectedCars.map((c) => {
            if (c[0].deposit > maxDeposit) maxDeposit = c[0].deposit;
        })
        setMaxDeposit(maxDeposit);
        selectedCars.map((c) => {
            if (c[0].franchise > maxFranchise) maxFranchise = c[0].franchise;
        })
        setMaxFranchise(maxFranchise);
    }, [selectedCars])
    const computeRentalPrice = (order) => {
        console.log("computing");
        let tmp = [...rentalPrices];
        if (order.duration <= 4) {
            order.selectedCars.map((c, index) => {
                console.log("car", c);
                tmp.push({ id: c[0].id, price: c[0].price_per_day_1_10 });
            })
            setRentalPrices(tmp);

            console.log("rental prices are", tmp);
        }
        else {
            order.selectedCars.map((c, index) => {
                console.log("car", c);
                tmp.push({ id: c[0].id, price: c[0].price_per_day_10_20 });
            })
            setRentalPrices(tmp);

            console.log("rental prices are", tmp);
        }
    }
    const handleConfirmButtonClick = (e) => {
        let data = {
            "order": JSON.parse(localStorage.getItem("excl_rental_order")),
            "user": JSON.parse(localStorage.getItem("excl_user"))
        };
        setConfirmProgress(true);
        axios.post(`${BACKEND_URL}order`, data)
            .then((response) => {
                console.log(response);
                setConfirmProgress(false);
                notifier.success("Your order has been successfully sent. Please check your email.", {});
                notifier.modal("Thanks for choosing our services. Please check your email in order to complete your booking. And download invoice. <strong className='text-info'>this invoice is the document that prove that you book online</strong>.<br/><h5>You will be redirected to the home page after 5 seconds</h5>");
                setTimeout(() => {
                    //   localStorage.removeItem("excl_rental_order");
                    window.location.replace(`${APP_URL}`);
                }, 5000);
            })
            .catch((err) => {
                notifier.alert("Something went wrong. Please try later.", {});
                setConfirmProgress(false);

            })
    }

    useEffect(() => {
        let sum_options = 0;
        let sum_cars = 0;
        selectedOptions.map((option, index) => {
            sum_options += option.price_per_day * option.quantity * duration;

        })
        console.log("total options:", sum_options);
        setTotalOptions(sum_options);
        /////////////////////////////////////////////////////////////
        selectedCars.map((car, index) => {
            sum_cars += rentalPrices[index].price * car.selected_quantity * duration;
            console.log(sum_cars);
        })
        setTotalCars(sum_cars);
        console.log("total cars:", sum_cars);


    }, [rentalPrices, selectedCars, duration, selectedOptions])

    useEffect(() => {
        setTotal(totalCars + totalOptions + pickupLocation.fees + dropLocation.fees);

    }, [totalCars, totalOptions]);
    return (
        <div className="mt-5">
            <h2 className="text-center">Your order confirmation</h2>
            <hr />
            <div className="container shadow p-3 mb-5 bg-white rounded">
                <div className="row">
                    <table className="table table-striped">
                         <tr>
                            <td style={{ "background-color": "#c58611", "color": "white" }}>
                                Your personal informations
                            </td>
                        </tr>
                        <tr>
                            <td>{userDetails.firstname + " " + userDetails.lastname}</td>
                            <td>{userDetails.email}</td>
                            <td>{userDetails.phone}</td>
                            <td>--</td>
                            <td>--</td>
                        </tr>
                        <tr>
                            <td style={{ "background-color": "#c58611", "color": "white" }}>                            Rental date / location informations</td>
                        </tr>
                        <tr>
                            <td>Pickup</td>
                            <td>{pickupLocation.name}</td>
                            <td>{moment(pickupTime).format('MMMM Do YYYY, h:mm:ss a')}</td>
                            <td>--</td>
                            <td>
                                <strong>{pickupLocation.fees} €</strong>
                            </td>
                        </tr>
                        <tr>
                            <td>Drop off</td>
                            <td>{dropLocation.name}</td>
                            <td>{moment(dropTime).format('MMMM Do YYYY, h:mm:ss a')}</td>
                            <td>--</td>
                            <td>
                                <strong> {dropLocation.fees} €</strong>
                            </td>
                        </tr>
                        <tr>
                            <td>Duration</td>
                            <td></td>
                            <td>{`${duration} days`}</td>
                        </tr>
                        <tr>
                            <td style={{ "background-color": "#c58611", "color": "white" }}>Selected cars</td>
                        </tr>
                        {selectedCars.map((car) => (
                            <tr>
                                <td><img
                                    className="img-fluid"
                                    style={{ "maxHeight": "100px" }}
                                    src={`${STORAGE_URL}${car[0].picture}`}
                                    alt="" /></td>
                                <td>
                                    {car.selected_quantity + " x " + car[0].company + " " + car[0].name}</td>
                                <td>--</td>

                                <td>
                                    <strong>{rentalPrices.filter(ca => ca.id == car[0].id)[0].price}€/day</strong>
                                </td>
                                <td>
                                    <strong>{rentalPrices.filter(ca => ca.id == car[0].id)[0].price * duration * car.selected_quantity} €</strong>
                                </td>

                            </tr>
                        ))}
                        <tr>
                            <td style={{ "background-color": "#c58611", "color": "white" }}>
                                Extra options
                            </td>
                        </tr>
                        {selectedOptions.length != 0 && selectedOptions.map((s) => (
                            <tr>
                                <td></td>
                                <td>{s.quantity + " x " + s.name}</td>
                                <td>--</td>

                                <td>
                                    <strong> {s.price_per_day} €/day</strong>
                                </td>
                                <td>
                                    <strong>{s.price_per_day * duration * parseInt(s.quantity, 10)} €</strong>
                                </td>
                            </tr>
                        ))}
                        {selectedOptions == 0 &&
                            <tr>
                                <td>No extra options selected</td>
                            </tr>
                        }
                        <tr>
                            <td style={{
                                "background-color": "#c58611",
                                "color": "white"
                            }}> Advance</td>
                        </tr>
                        <tr>
                            <td>Payment Method</td>
                            <td>{selectedPaymentMethod}</td>
                            <td></td>
                            <td><h5></h5></td>
                            <td style={{ "width": "120px" }}></td>

                        </tr>
                        <tr>
                            <td>Deposit <small className="text-danger">( The maximum of all deposit value of the choosed cars)</small></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td><h5>
                                <strong> {maxDeposit} €</strong>
                            </h5></td>
                        </tr>
                        <tr>
                            <td>Franchise <small className="text-danger">( The maximum of all franchise value of the choosed cars)</small></td>
                            <td></td>
                            <td></td>
                            <td><h5></h5></td>
                            <td style={{ "width": "120px" }}><h4>
                                <strong className="text-primary">{maxFranchise} €</strong>
                            </h4></td>

                        </tr>


                        <tr>
                            <td style={{ "background-color": "#c58611", "color": "white" }}> Total</td>
                        </tr>
                        <tr>
                            <td>Price Without taxes</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td><h5>
                                <strong> {total} €</strong>
                            </h5></td>
                        </tr>
                        <tr>
                            <td>Price With taxes</td>
                            <td></td>
                            <td></td>
                            <td><h5>x 20%</h5></td>
                            <td style={{ "width": "120px" }}><h4>
                                <strong className="text-primary">{(total * 20) / 100 + total} €</strong>
                            </h4></td>

                        </tr>

                    </table>

                </div>
                <div className="row mb-5">
                    <div className="col-sm-12 text-center">
                        {!confirmProgress &&
                            <button
                                onClick={handleConfirmButtonClick}
                                className="btn btn-primary">
                                <span> I confirm my rental order</span>
                            </button>}
                        {confirmProgress &&
                            <button
                                className="btn btn-primary" disabled>
                                <i className="fas fa-circle-notch fa-spin"></i>
                                <span> I confirm my rental order</span>
                            </button>}
                    </div>
                </div>
            </div>
        </div>
    )
}