import React from "react";
import CustomerInformations from "./order/CustomerInformations";
import banner from "../images/banner_2.jpeg"


export default function Authentication(props) {



    return (
        <div>
        <section className="hero-wrap hero-wrap-2 js-fullheight" style={{ "background-image": `url(${banner})` }} data-stellar-background-ratio="0.5">
        <div className="overlay"></div>
        <div className="container">
          <div className="row no-gutters slider-text js-fullheight align-items-end justify-content-start">
            <div className="col-md-9 ftco-animate pb-5">
              <p className="breadcrumbs"><span className="mr-2"><a href="index.html">Home <i className="ion-ios-arrow-forward"></i></a></span> <span>Authentication <i className="ion-ios-arrow-forward"></i></span></p>
              <h1 className="mb-3 bread">Login to your account</h1>
            </div>
          </div>
        </div>
      </section>
        <div className="" style={{"margin-top":"150px"}}>
            <CustomerInformations notifer={props.notifier} />
        </div>
        </div>
    );
}