import React from 'react'

export default function Footer() {
    return ( <div>
    <footer className="ftco-footer ftco-bg-dark ftco-section">
        <div className="container">
            <div className="row mb-5">
                <div className="col-md">
                    <div className="ftco-footer-widget mb-4">
                        <h2 className="ftco-heading-2"><img style={{"width":"150px"}} src="./images/logo.png" className="img-fluid" alt="" /></h2>
                        <p>Make your order now, let us hear see you drivingw.</p>
                        <ul className="ftco-footer-social list-unstyled float-md-left float-lft mt-5">
                            <li className="ftco-animate"><a href="#"><span className="icon-snapchat"></span></a></li>
                            <li className="ftco-animate"><a href="https://www.instagram.com/exclusifcar/"><span className="icon-instagram"></span></a></li>
                        </ul>
                    </div>
                </div>
                <div className="col-md">
                    <div className="ftco-footer-widget mb-4 ml-md-5">
                        <h2 className="ftco-heading-2">Information</h2>
                        <ul className="list-unstyled">
                            <li><a href="#" className="py-2 d-block">About</a></li>
                            <li><a href="#" className="py-2 d-block">Services</a></li>
                            <li><a href="#" className="py-2 d-block">Cars</a></li>
                            <li><a href="#" className="py-2 d-block">Privacy &amp; Cookies Policy</a></li>
                        </ul>
                    </div>
                </div>
                <div className="col-md">
                    <div className="ftco-footer-widget mb-4">
                        <h2 className="ftco-heading-2">Customer Support</h2>
                        <ul className="list-unstyled">
                            <li><a href="#" className="py-2 d-block">FAQ</a></li>
                            <li><a href="#" className="py-2 d-block">Payment Option</a></li>
                            <li><a href="#" className="py-2 d-block">Booking Tips</a></li>
                            <li><a href="#" className="py-2 d-block">Contact Us</a></li>
                        </ul>
                    </div>
                </div>
                <div className="col-md">
                    <div className="ftco-footer-widget mb-4">
                        <h2 className="ftco-heading-2">Have a Questions?</h2>
                        <div className="block-23 mb-3">
                            <ul>
                                <li><span className="icon icon-map-marker"></span><span className="text">IMM JASSIM N71 AVENUE MOHAMED 5 GUELIZ MARRAKECH</span></li>
                                <li><a href="#"><span className="icon icon-phone"></span><span className="text">+212 6 70 42 21 05</span></a></li>
                                <li><a href="#"><span className="icon icon-envelope"></span><span className="text">contact@exclusifcar.com</span></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12 text-center">

                    <p>
                        Copyright &copy;
                        <script>
                            document.write(new Date().getFullYear());
                        </script> All rights reserved | This template is made with <i className="icon-heart color-danger" aria-hidden="true"></i> by <a href="https://paginale.com" target="_blank">Paginale</a>
                    </p>
                </div>
            </div>
        </div>
    </footer>
        </div>
    )
}