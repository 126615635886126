import * as React from 'react';

import Trip from "./home/Trip";
import CarList from './cars/CarList';
import OrderRecap from "./order/OrderRecap.js";
import OrderOptions from './order/OrderOptions';
import OrderConfirmation from "./order/OrderConfirmation.js";
import PaymentMethod from "./order/PaymentMethod.js";
import TimeLine from './Layout/TimeLine';
import banner from "../images/banner_4.jpeg"

import CustomerInformations from './order/CustomerInformations';
import { APP_URL } from '../config';
export default function Order(props) {


  console.log(props);
  // const [step,setStep] = React.useState(1);
  // const [userDetails,setUserDetails] = React.useState({});

  var step = window.location.search.split("=")[1];
  if (step == 5 && JSON.parse(localStorage.getItem("excl_user"))) {
    window.location.replace(`${APP_URL}book?step=6`);
  }

  return (<>
    <section className="hero-wrap hero-wrap-2 js-fullheight" style={{ "background-image": `url(${banner})` }} data-stellar-background-ratio="0.5">
      <div className="overlay"></div>
      <div className="container">
        <div className="row no-gutters slider-text js-fullheight align-items-end justify-content-start">
          <div className="col-md-9 ftco-animate pb-5">
            <p className="breadcrumbs">
              <span className="mr-2"><a href="index.html">Home <i className="ion-ios-arrow-forward"></i></a></span>
              <span>Booking <i className="ion-ios-arrow-forward"></i></span></p>
            <h1 className="mb-3 bread">Booking</h1>
          </div>
        </div>
      </div>
    </section>

    {step == 1 && (<div><OrderRecap step={step} /><Trip /></div>)}
    {step == 2 && (<div><OrderRecap step={step} /><CarList notifier={props.notifier} /></div>)}
    {step == 3 && (<div><OrderRecap step={step} /><OrderOptions notifier={props.notifier} /></div>)}
    {step == 4 && (<div><OrderRecap step={step} /><PaymentMethod notifier={props.notifier} /></div>)}
    {step == 5 && (<div><OrderRecap step={step} /><CustomerInformations notifier={props.notifier} /></div>)}
    {step == 6 && <div><OrderRecap step={step} /><OrderConfirmation notifier={props.notifier} /></div>}

  </>
  );
}