import { React, useEffect, useState } from 'react';
import axios from "axios";
import { BACKEND_URL, APP_URL } from "../../config";
import moment from "moment";
import { useTranslation, initReactI18next } from "react-i18next";

export default function Trip(props) {
    const { notifier } = props;

    const { t } = useTranslation();

    const [pickupLocations, setPickupLocations] = useState([]);
    const [dropLocations, setDropLocations] = useState([]);

    const [pickupLocation, setPickupLocation] = useState({});
    const [dropLocation, setDropLocation] = useState({});


    const [errors, setErrors] = useState([false, false, false, false, false, false, false, true]);
    const [allowSubmit, setAllowSubmit] = useState(false);
    const [pickupTime, setPickupTime] = useState();
    const [dropTime, setDropTime] = useState();



    const handlePickupTimeChange = (e) => {
        setErrors(errors.fill(false));

        setPickupTime(e.target.value);
    }
    const handlePickupLocationChange = (e) => {
        setErrors(errors.fill(false));


        setPickupLocation(pickupLocations.filter(d => d.id == e.target.value)[0]);
    }
    const handleDropLocationChange = (e) => {

        setErrors(errors.fill(false));

        setDropLocation(dropLocations.filter(d => d.id == e.target.value)[0]);
    }
    const handleDropTimeChange = (e) => {
        setErrors(errors.fill(false));

        setDropTime(e.target.value);
    }

    const validate = () => {
        const tmp = [...errors];
        console.log({ pickupTime, dropTime, pickupLocation, dropLocation });
        //
        if (pickupTime == undefined) {
            tmp[0] = true;
        }
        //
        if (dropTime == undefined) {
            tmp[1] = true;
        }
        //
        if (dropTime != undefined
            && dropTime != undefined
            && computeDuration(pickupTime, dropTime) <= 0) {
            tmp[2] = true;

        }
        //
        if (pickupTime != undefined
            && dropTime != undefined
            && computeDuration(pickupTime, dropTime) < 3) {
            tmp[3] = true;

        }
        console.log("testing locations");
        if (pickupLocation.id == undefined) {
            console.log("yesssss");
            tmp[4] = true;
        }
        if (dropLocation.id == undefined) {
            tmp[5] = true;
        }
        if (pickupTime != undefined && computeDifferenceFromToday() <= 0) {
            tmp[6] = true;
        }
        setErrors(tmp);
    }

    useEffect(() => {
        errors.every((value) => value == false) ? setAllowSubmit(true) : setAllowSubmit(false);
    }, [errors]);

    useEffect(() => {
        if (allowSubmit == true) {
            localStorage.setItem("excl_rental_order", JSON.stringify({
                pickupLocation,
                dropLocation,
                pickupTime,
                dropTime,
                duration: computeDuration()
            }));
            notifier.success("You choosed the right locations, see you next step.", {})
            setTimeout(() => {
                window.location.replace(`${APP_URL}book?step=2`);

            }, 1000);

        }
    }, [allowSubmit]);


    const handleSubmitClick = (e) => {
        let tmp = [...errors];
        tmp[7] = false;
        setErrors(tmp);
        e.preventDefault();
        validate();


    }
    const computeDuration = () => {
        let start = moment(pickupTime);
        let end = moment(dropTime);
        return end.diff(start, 'days');
    }

    const computeDifferenceFromToday = () => {
        console.log(moment(pickupTime).diff(moment(new Date())));
        return moment(pickupTime).diff(moment(new Date()));
    }
    useEffect(() => {
        axios.get(`${BACKEND_URL}cities`).then((response) => {
            setPickupLocations(response.data);
            setDropLocations(response.data);
        }).catch((err) => {
            console.log("cannot retrieve locations.", err);
        })
    }, []);
    useEffect(() => { console.log(dropLocations) }, [dropLocations])

    return (
        <section className="ftco-section ftco-no-pt bg-light mt-5">
            <div className="container">
                <div className="row no-gutters">
                    <div className="col-md-12	featured-top">
                        <div className="row no-gutters">
                            <div className="col-md-4 d-flex align-items-center">
                                <div action="#" className="request-form ftco-animate bg-primary">
                                    <h2>{t('make-trip.title')}</h2>
                                    <div className="form-group">
                                        <label for="" className="label">{t('make-trip.pick-up-location')}</label>
                                        <select
                                            style={{ "color": "black" }}
                                            required type="text"
                                            defaultValue={1}
                                            onChange={handlePickupLocationChange}
                                            className="form-control"
                                        >
                                            <option value="-1"> ------------------- </option>
                                            {pickupLocations.map((p) => (
                                                <option
                                                    style={{ "color": "black" }}


                                                    value={p.id}>{p.name}</option>
                                            ))}
                                        </select>
                                        {errors[4] && <div> <span className='text-danger'>- Please choose a pickup location</span> <br /> </div>}

                                    </div>
                                    <div className="form-group">
                                        <label for="" className="label">{t('make-trip.drop-off-location')}</label>
                                        <select
                                            required
                                            type="text"
                                            onChange={handleDropLocationChange}
                                            className="form-control"
                                        >

                                            <option value="-1"> ------------------- </option>

                                            {dropLocations.map((d) => (
                                                <option
                                                    style={{ "color": "black" }}


                                                    value={d.id}>{d.name}</option>
                                            ))}
                                        </select>
                                        {errors[5] && <div> <span className='text-danger'>- Please choose a drop off location</span> <br /> </div>}

                                    </div>

                                    <div className="form-group">
                                        <label for="" className="label">{t('make-trip.pick-up-time')}</label>
                                        <input required
                                            type="datetime-local"
                                            onChange={handlePickupTimeChange}
                                            className="form-control"
                                            placeholder="Pickup Time" />
                                        {errors[0] && <span className='text-danger'>- Please choose a pickup time</span>}
                                        {errors[6] && <span className='text-danger'>- The pickup date need to be superior or equal to tomorrow</span>}

                                    </div>
                                    <div className="form-group">
                                        <label for="" className="label">{t('make-trip.drop-off-location')}</label>
                                        <input required
                                            type="datetime-local"
                                            onChange={handleDropTimeChange}
                                            className="form-control" placeholder="Drop Time" />
                                        {errors[1] && <div> <span className='text-danger'>- Please choose a drop off Time</span> <br /> </div>}
                                        {errors[2] && <div> <span className='text-danger'>- The drop off date need to be superior that the pickup date </span> <br /> </div>}
                                        {errors[3] && <div> <span className='text-danger'>- The duration must be at least 3 days, choose another drop date. </span> <br /> </div>}

                                    </div>
                                    <div className="form-group">
                                        <input type="submit" onClick={handleSubmitClick}
                                            value={t('make-trip.rent-car')}
                                            className="btn btn-secondary py-3 px-4" ></input>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-8 d-flex align-items-center">
                                <div className="services-wrap rounded-right w-100">
                                    <h3 className="heading-section mb-4">Better Way to Rent Your Perfect Cars</h3>
                                    <div className="row d-flex mb-4">
                                        <div className="col-md-4 d-flex align-self-stretch ftco-animate">
                                            <div className="services w-100 text-center">
                                                <div className="icon d-flex align-items-center justify-content-center"><span className="flaticon-route"></span></div>
                                                <div className="text w-100">
                                                    <h3 className="heading mb-2">Choose Your Pickup/Drop Location</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 d-flex align-self-stretch ftco-animate">
                                            <div className="services w-100 text-center">
                                                <div className="icon d-flex align-items-center justify-content-center"><span className="flaticon-handshake"></span></div>
                                                <div className="text w-100">
                                                    <h3 className="heading mb-2">Select your car and options</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 d-flex align-self-stretch ftco-animate">
                                            <div className="services w-100 text-center">
                                                <div className="icon d-flex align-items-center justify-content-center"><span className="flaticon-rent"></span></div>
                                                <div className="text w-100">
                                                    <h3 className="heading mb-2">Enjoy your driving</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                               
                                        <a
                                            href={"/cars"}
                                            style={{margin:"auto"}}
                                            className="btn btn-primary py-3 px-4 ">
                                            Discover our cars
                                        </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </ div>
        </section>
    )
}