import axios from "axios";
import react, { useEffect, useState } from "react";
import { BACKEND_URL } from "../../config";



var checksum = true;
export default function CustomerInformations(props) {
    console.log("in informations ", props)
    const notifier = props.notifier;
    const [alreadyHaveAccount, setAlreadyHaveAccount] = useState(true);
    const [firstname, setFirstname] = useState("");
    const [lastname, setLastName] = useState("");
    const [birthday, setBirthday] = useState("");
    const [country, setCountry] = useState("");
    const [city, setCity] = useState("");
    const [postalCode, setPostalCode] = useState("");
    const [adress, setAdress] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [personalDocumentNumber, setPersonalDocumentNumber] = useState("");
    const [matchedPassword, setMatchedPassword] = useState(false);
    const [loginProgress, setLoginProgress] = useState(false);
    const [createAccountProgress, setCreateAccountProgress] = useState(false);

    const [loginError, setLoginError] = useState(false);
    const [createAccountError, setCreateAccountError] = useState(false);

    const [loginEmail, setLoginEmail] = useState("");
    const [loginPassword, setLoginPassword] = useState("");



    const [countries, setCountries] = useState([]);


    const [errors, setErrors] = useState({
        firstname: false,
        lastname: false,
        adress: false,
        city: false,
        phone: false,
        birthday: false,
        postal_code: false,
        country: false,
        email: false,
        password: false,
        confirm_password: false,
        personal_document_number: false,


    })
    // const [errorsLogin,setErrorsLogin] = ({
    //     loginEmail:false,
    //     loginPassword:false
    // })
    const [allowSubmit, setAllowSubmit] = useState(false);

    ///////////////////////////
    useEffect(() => {
        axios.get(`${BACKEND_URL}countries`)
            .then((response) => {
                console.log(response.data);
                setCountries(response.data);
            })
            .catch((err) => {
                console.log(`error occured ${err}`);
            })
    }, []);

    //////////////////////////////
    useEffect(() => {
        setCreateAccountError(false);
        setAllowSubmit(true);

        console.log(errors, checksum)
        if (Object.values(errors).every(field => field == false) && !checksum) {
            console.log("all are false");
            setAllowSubmit(true);
        }
        else {
            setAllowSubmit(false);
            checksum = true;

        }
    }, [errors])

    useEffect(() => {
        console.log("allow submit changed", allowSubmit);
        if (allowSubmit == true) {
            setCreateAccountProgress(true);
            let data = {
                "firstname": firstname,
                "lastname": lastname,
                "email": email,
                "password": password,
                "city": city,
                "country": country,
                "adress": adress,
                "postal_code": postalCode,
                "phone": phoneNumber,
                "personal_document_number": personalDocumentNumber
            }
            axios.post(`${BACKEND_URL}customer/register`, data).then((res) => {
                console.log("success");
                checksum = true;

                // notifier.success('Your account has been created successfully !', {})

                setCreateAccountProgress(false);
                setCreateAccountError(false);

            }).catch((err) => {
                console.log("error");
                setCreateAccountProgress(false);
                setCreateAccountError(true);
                // notifier.alert('Something went wrong please try again.', {})


            });
        }
    }, [allowSubmit]);

    /////////////////////////////
    const validate = () => {
        console.log("validation process");
        let tmp = { ...errors };
        if (firstname == "") {
            tmp.firstname = true;
        }
        if (lastname == "") {
            tmp.lastname = true;
        }
        if (email == "") {
            tmp.email = true;
        }
        if (city == "") {
            tmp.city = true;
        }
        if (phoneNumber == "") {
            tmp.phone = true;
        }
        if (country == "") {
            tmp.country = true;
        }
        if (password == "") {
            tmp.password = true;
        }
        if (personalDocumentNumber == "") {
            tmp.personal_document_number = true;
        }

        setErrors(tmp);

    }


    //////////////////////
    const err = {
        firstname: false,
        lastname: false,
        adress: false,
        city: false,
        phone: false,
        birthday: false,
        postal_code: false,
        country: false,
        email: false,
        password: false,
        confirm_password: false,
        personal_document_number: false
    };
    const handlePasswordChange = (e) => {
        setErrors(err);
        setPassword(e.target.value)
    }
    const handleLoginPasswordChange = (e) => {
        setErrors(err);
        setLoginPassword(e.target.value)
    }
    const handleLoginEmailChange = (e) => {
        setErrors(err);
        setLoginEmail(e.target.value)
    }
    const handleFirstNameChange = (e) => {
        setErrors(err);
        setFirstname(e.target.value);
    }
    const handleLastNameChange = (e) => {
        setErrors(err);
        setLastName(e.target.value);
    }
    const handleBirthdayChange = (e) => {
        setErrors(err);
        setBirthday(e.target.value);
    }
    const handlePostalCodeChange = (e) => {
        setErrors(err);
        setPostalCode(e.target.value);
    }
    const handlePersonalDocumentNumberChange = (e) => {
        setErrors(err);
        setPersonalDocumentNumber(e.target.value);
    }
    const handleCityChange = (e) => {
        setErrors(err);
        setCity(e.target.value);
    }
    const handleAdressChange = (e) => {
        setErrors(err);
        setAdress(e.target.value);
    }
    const handleCountryChange = (e) => {
        setErrors(err);
        setCountry(e.target.value);
    }
    const handleEmailChange = (e) => {
        setErrors(err);
        setEmail(e.target.value);
    }
    const handlePhoneNumberChange = (e) => {
        setErrors(err);
        setPhoneNumber(e.target.value);
    }
    const handleMatchPassword = (e) => {
        if (1) {
            setMatchedPassword(true);
        }
        else {
            setMatchedPassword(true);

        }
    }
    const handleCreateAccountButtonClick = (e) => {
        let tmp = { ...errors };
        checksum = false;
        console.log("checksum is now ", checksum);
        setErrors(tmp);
        validate();

    }
    const handleLoginButtonClick = (e) => {
        setLoginProgress(true);

        let data = {
            "email": loginEmail,
            "password": loginPassword
        }
        axios.post(`${BACKEND_URL}customer/login`, data).then((res) => {
            console.log("success");

            setLoginProgress(false);
            setLoginError(false);
            localStorage.setItem("excl_user", JSON.stringify(res.data));

            // notifier.success('You are now logged in !', {});
            setTimeout(() => {
                window.location.reload();
            }, 1000);

        }).catch((err) => {
            console.log("error");
            setLoginProgress(false);
            setLoginError(true);
            // notifier.alert('An error occured, please try again!', {})

        });;
    }

    //////////////////////////
    return (
        <div className="container" style={{ marginBottom: "10rem" }}>
            {window.location.pathname != '/authentication' &&
                <>

                    <h2 className="text-center">Your personal informations</h2>
                    <hr />

                </>

            }
            {alreadyHaveAccount && <div className="row">
                <div className="alert alert-info col-sm-12 mx-auto text-center">
                    <span className="">You don't have an account? <a href="#" onClick={() => { setAlreadyHaveAccount(false) }}>register now</a></span>

                </div>
                <div className="col-sm-12 row text-center">
                    <div className="form-group col-sm-5 mx-auto">
                        <label htmlFor="">Email</label>
                        <input type="email" onChange={handleLoginEmailChange} className="form-control" />
                        <label htmlFor="">Password</label>
                        <input type="password" onChange={handleLoginPasswordChange} className="form-control" />
                        <div className="col-sm-12">
                            {loginError && <small className="text-danger">Please verify your email/password</small>}

                        </div>
                        {loginProgress &&
                            <button className="btn btn-primary mt-1" disabled>
                                <i className="fas fa-circle-notch fa-spin"></i>
                                <span>Login</span></button>
                        }
                        {!loginProgress &&
                            <button onClick={handleLoginButtonClick} className="btn btn-primary mt-1"><span>Login</span></button>
                        }

                    </div>

                </div>

            </div>}
            {!alreadyHaveAccount && <div className="form-group row">
                <div className="col-sm-12 text-center alert alert-info">
                    <span className="text-center">You Already have an account? <a href="#" onClick={() => { setAlreadyHaveAccount(true) }}>login now</a></span>

                </div>

                <div className="col-sm-5">
                    <label htmlFor="">Firstname <span className="text-danger">*</span> </label>
                    <input type="text" onChange={handleFirstNameChange} className="form-control" />
                    {errors.firstname && <div><small className="text-danger">First name is required</small> <br /></div>}
                    <label htmlFor="">Lastname <span className="text-danger">*</span></label>
                    <input type="text" onChange={handleLastNameChange} className="form-control" />
                    {errors.lastname && <div><small className="text-danger">Last name is required</small> <br /></div>}
                    <label htmlFor="">Birthday <span className="text-danger">*</span></label>
                    <input type="date" onChange={handleBirthdayChange} className="form-control" />
                    <label htmlFor="">Phone number <span className="text-danger">*</span></label>
                    <input type="tel" onChange={handlePhoneNumberChange} className="form-control" />
                    {errors.phone && <div><small className="text-danger">Phone number is required</small> <br /></div>}
                    <label htmlFor="">Email <span className="text-danger">*</span></label>
                    <input type="email" onChange={handleEmailChange} className="form-control" />
                    {errors.email && <div><small className="text-danger">Email is required</small> <br /></div>}

                    <label htmlFor="">Personal document number <span className="text-danger">*</span></label>
                    <input type="text" onChange={handlePersonalDocumentNumberChange} className="form-control" />
                    {errors.personal_document_number && <div><small className="text-danger">Personal document number is required</small> <br /></div>}


                </div>
                <div className="col-sm-5">

                    <label htmlFor="">Adress</label>
                    <textarea onChange={handleAdressChange} id="" className="form-control"></textarea>
                    {errors.adress && <div><small className="text-danger">Adress is required</small> <br /></div>}

                    <label htmlFor="">City</label>
                    <input type="text" onChange={handleCityChange} className="form-control" />
                    {errors.city && <div><small className="text-danger">City is required</small> <br /></div>}

                    <label htmlFor="">postal code</label>
                    <input type="text" onChange={handlePostalCodeChange} className="form-control" />
                    <label htmlFor="">Country</label>
                    <select type="text" onChange={handleCountryChange} className="form-control" >
                        {countries.map((c) => (
                            <option value={c.id}>{c.nicename}</option>
                        ))}
                    </select>
                    {errors.country && <div><small className="text-danger">Country is required</small> <br /></div>}

                    <hr />
                    <label htmlFor="">Choose a password<span className="text-danger">*</span></label>
                    <input type="password" onChange={handlePasswordChange} className="form-control" />
                    {errors.password && <div><small className="text-danger">Password is required</small> <br /></div>}

                    <label htmlFor="">Confirm the password<span className="text-danger">*</span></label>
                    <input type="password" onChange={handleMatchPassword} className="form-control" />
                </div>
                <div className="col-sm-12 text-center">
                    {createAccountError && <small className="text-danger">Something wrong, please try again.</small>}

                </div>
                <div className="col-sm-12 text-center mt-3">
                    {!createAccountProgress &&
                        <button className="btn btn-primary mt-1" onClick={handleCreateAccountButtonClick}> <span>Create my account</span></button>
                    }
                    {createAccountProgress &&
                        <button className="btn btn-primary mt-1" disabled={true}><i className="fas fa-circle-notch fa-spin"></i><span>Create my account</span></button>
                    }
                </div>
            </div>}
        </div>
    );
}