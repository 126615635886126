import React, { useEffect,useState } from "react";
import axios from "axios";
import { APP_URL, BACKEND_URL, STORAGE_URL } from "../config";
import bg_1 from "../images/bg_1.jpg";
import car_1 from "../images/car-1.jpg";
import car_2 from "../images/car-2.jpg";
import car_3 from "../images/car-3.jpg";
import car_4 from "../images/car-4.jpg";
import bg_3 from "../images/bg_3.jpg";
import person_1 from "../images/pic_1.jpg";
import about from "../images/about.jpg";
import Trip from "./home/Trip";
import banner from "../images/banner_2.jpeg"

export default function Home(props) {

    const [featuredCars,setFeaturedCars] = useState([]);


    useEffect(()=> {
    
        axios.get(`${BACKEND_URL}cars`).then((res) => {
            console.log(res.data);
            setFeaturedCars(res.data);
        }).catch((err)=>{
            console.log(err);
        })
    },[]);
    




    return (
    <div>
        <div className="hero-wrap ftco-degree-bg" style={{ "background-image": `url(${banner})` }} data-stellar-background-ratio="0.5">
            <div className="overlay"></div>
            <div className="container">
                <div className="row no-gutters slider-text justify-content-start align-items-center justify-content-center">
                    <div className="col-lg-8 ftco-animate">
                        <div className="text w-100 text-center mb-md-5 pb-md-5">
                            <h1 className="mb-4">Fast &amp; Easy Way To Rent A Car</h1>
                            <p style={{ "font-size": "18px" }}>Welcome to Exclusif car, Your best car rental provider</p>
                            <a href={`${APP_URL}assets/videos/intro.mp4`} className="icon-wrap popup-vimeo d-flex align-items-center mt-4 justify-content-center">
                                <div className="icon d-flex align-items-center justify-content-center">
                                    <span className="ion-ios-play"></span>
                                </div>
                                <div className="heading-title ml-5">
                                    <span>Start browsing cars</span>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <Trip  t={props.t} notifier={props.notifier}/>



        <section className="ftco-section ftco-about">
            <div className="container">
                <div className="row no-gutters">
                    <div className="col-md-6 p-md-5 img img-2 d-flex justify-content-center align-items-center" style={{ "background-image": `url(${person_1})` }}>
                    </div>
                    <div className="col-md-6 wrap-about ftco-animate">
                        <div className="heading-section heading-section-white pl-md-5">
                            <span className="subheading">About us</span>
                            <h2 className="mb-4">Welcome to EXCLUSIF CARS</h2>

                            <p>Exclusif Car is a premier car rental agency located in the vibrant city of Marrakech, Morocco. Our company offers a wide range of normal and luxury vehicles to suit your needs and budget.</p>
                            <p>At Exclusif Car, we understand the importance of reliable transportation and strive to provide our customers with the best possible experience. We offer top-quality cars that are regularly maintained and serviced to ensure maximum comfort and safety on the road.</p>
                            <p>Our team of friendly and knowledgeable staff is dedicated to providing exceptional customer service and making your rental experience as smooth and hassle-free as possible. Whether you're in Marrakech for business or pleasure, we have the perfect car for you.</p>
                            <p>We take pride in our commitment to quality and affordability, and we work hard to ensure that our prices are competitive and fair. When you rent from Exclusif Car, you can rest assured that you're getting the best value for your money.</p>
                            <p><a href="./cars" className="btn btn-primary py-3 px-4">Search Vehicle</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

  




    

  

        <section className="ftco-counter ftco-section img bg-light" id="section-counter">
            <div className="overlay"></div>
            <div className="container">
                <div className="row">
                    <div className="col-md-6 col-lg-3 justify-content-center counter-wrap ftco-animate">
                        <div className="block-18">
                            <div className="text text-border d-flex align-items-center">
                                <strong className="number" data-number="5">0</strong>
                                <span>Year <br />Experienced</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-3 justify-content-center counter-wrap ftco-animate">
                        <div className="block-18">
                            <div className="text text-border d-flex align-items-center">
                                <strong className="number" data-number="16">0</strong>
                                <span>Total <br />Cars</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-3 justify-content-center counter-wrap ftco-animate">
                        <div className="block-18">
                            <div className="text text-border d-flex align-items-center">
                                <strong className="number" data-number="302">0</strong>
                                <span>Happy <br />Customers</span>
                            </div>
                        </div>
                    </div>
                 
                </div>
            </div>
        </section>
    </div>
    )
}