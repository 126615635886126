
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
export const Lang = () => {


    const { i18n} = useTranslation();


    useEffect(() => {
        const local = localStorage.getItem('excl_local');
        console.log("trans", i18n)

        if (local) changeLanguage(local);
        else { localStorage.setItem('excl_local', 'fr'); changeLanguage('fr') }

    }, [i18n])

    let changeLanguage = (lang) => {

        // switch (lang) {
        //     case 'en':
        //         i18n.changeLanguage();
        //         break;
        //     case 'fr':
        //         i18n.changeLanguage();
        //         break;
        // }
    }


    /**
  * 
  * @param {*} e 
  */
    const handleLanguageChange = (e) => {
        localStorage.setItem('excl_local', e.target.value);
    }



    return (<>
        <select style={{ "border": "none" }}
            onChange={handleLanguageChange}
            className='nav-link bg-transparent'>
            <option value="fr">FR</option>
            <option value="en">EN</option>
        </select></>);
}