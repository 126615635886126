import React, { useState, useEffect } from "react";
import axios from "axios";
import { BACKEND_URL,APP_URL } from "../../config";




export default function OrderOptions(props) {

    const notifier = props.notifier;

    const [options, setOptions] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [optionsCheck, setOptionsCheck] = useState([false, false, false, false, false, false]);



    const [quantity1Value, setQuantity1Value] = useState("1");
    const [quantity2Value, setQuantity2Value] = useState("1");
    const [quantity3Value, setQuantity3Value] = useState("1");
    const [quantity4Value, setQuantity4Value] = useState("1");
    const [quantity5Value, setQuantity5Value] = useState("1");
    const [quantity6Value, setQuantity6Value] = useState("1");

    const [disabled, setDisabled] = useState([true,true,true,true,true,true]);


    const handleCheck = (e) => {
        let new_options = [...optionsCheck];
        let new_disabled = [...disabled];


        let x = (parseInt(e.target.id, 10)+1);
        switch (x) {
            case 1:
                console.log("option checked");
                new_options[0] = !new_options[0];
                new_disabled[0] = !new_options[0];
                break;
            case 2:
                new_options[1] = !new_options[1];
                new_disabled[1] = !new_options[1];


                break;
            case 3:
                new_options[2] = !new_options[2];
                new_disabled[2] = !new_options[2];


                break;
            case 4:
                new_options[3] = !new_options[3];
                new_disabled[3] = !new_options[3];


                break;
            case 5:
                new_options[4] = !new_options[4];
                new_disabled[4] = !new_options[4];


                break;
            case 6:
                new_options[5] = !new_options[5];
                new_disabled[5] = !new_options[5];


                break;
               
            }
            setOptionsCheck(new_options);
            setDisabled(new_disabled);

            let new_selected_options = [...selectedOptions];
            let existing = selectedOptions.filter((o) => o.id == x);
           
            if(existing.length == 0) {
                let selected = options.filter(o => o.id == (x))[0];
                console.log("here is what you select",selected);
                new_selected_options.push(selected);
                setSelectedOptions(new_selected_options);
                notifier.success(`Extra "${selected.name} added successfully"`);  

            }
            else {
                let tmp = [];
                new_selected_options.map(n => {
                    if(n.id != x) tmp.push(n);
                });
                setSelectedOptions(tmp);  
                notifier.info(`Deleted extra`);  

            }
      

    }

    const handleQuantityClick = (e) => {
        console.log("clicked");
        if(e.target.disabled) {
        }

    }
    const handleQuantityChange = (e) => {
        let new_selected = [...selectedOptions];   
         let y = parseInt((e.target.id),10);
        let target = new_selected.filter(v => v.id == y)[0];
        let tmp = [];
        target.quantity = e.target.value;
        new_selected.map((cc)=> {
            if(cc.id != y)  {
                tmp.push(cc);
            }
        });
        tmp.push(target);
        setSelectedOptions(tmp);
   
    }
    useEffect(() => {
        axios.get(`${BACKEND_URL}orderOptions`).then((response) => {
            let new_response = [];
            response.data.map((oo)=>{
                new_response.push({...oo,"quantity":1})
            })
            setOptions(new_response);
        }).catch((e) => {
            console.log("an error occured", e);
        })
    }, []);
   
    useEffect(() => {
        console.log("changed");
    console.log(disabled);

    }, [disabled]);

    const handleContinueClick = (e) => {
		console.log("continue");
		localStorage.setItem("excl_rental_order", JSON.stringify({
			selectedOptions,
			...JSON.parse(localStorage.getItem("excl_rental_order"))
		}));

		window.location.replace(`${APP_URL}book?step=4`);
    }
    return (
        <div className="container">
            <h2 className="col-sm-12 text-center">Extra options</h2>
            <table className="table table-striped col-sm-12">
                <thead>
                    <th></th>

                    <th>Extra</th>
                    <th>Price/day</th>
                    <th>Deposit</th>
                    <th>Quantity</th>
                </thead>
                <tbody>
                    {options.map((o, index) => (
                        <tr>
                            <td><input
                                id={index}
                                type="checkbox"
                                className="text-primary"
                                onClick={handleCheck}
                            /></td>
                            <td>{o.name}</td>
                            <td>
                                <strong>
                                {o.price_per_day} €

                                </strong>
                                </td>
                            <td className="text-success">

                                Not required
                                </td>
                            <td className="row">
                                <input
                                    type="number"
                                    id={o.id}
                                    defaultValue={1}
                                    onClick={handleQuantityClick}
                                    onChange={handleQuantityChange}
                                    className="col-sm-3 mx-auto form-control"
                                    disabled={disabled[index]}
                                    min={1}
                                /></td>
                        </tr>
                    ))}


                </tbody>
            </table>
            <div className="row mb-5">
                <div className="col-sm-12">
                    <input type="checkbox" name="" id="" />
                    <small> I confirm that i have read the terms and accept the total requirements.</small>
                </div>
                <div className="col-sm-12 text-center mt-5">
                <button
                onClick={handleContinueClick}
                 className="col-sm-3 btn btn-primary mx-auto">
                     Continue to next step</button>

                </div>
            </div>
        </div>
    )
}