import React, { useEffect, useState } from "react";
import { APP_URL } from "../../config";
import "../../timeline.css"


export default function TimeLine(props) {

    const [finishedSteps, setFinishedSteps] = useState([false, false, false, false, false]);

    useEffect(() => {
        let tmp = [...finishedSteps];
        let tmp_step = props.step - 1;
        switch (tmp_step) {
            case 1:
                tmp[0] = true;
                break;
            case 2:
                tmp[0] = true;
                tmp[1] = true;
                break;
            case 3:
                tmp[0] = true;
                tmp[1] = true;
                tmp[2] = true;
                break;
            case 4:
                tmp[0] = true;
                tmp[1] = true;
                tmp[2] = true;
                tmp[3] = true;
                break;

        }
        setFinishedSteps(tmp);
    }, []);
    return (
        <div className="mb-5">
            <div className="stepwizard">
                <div className="stepwizard-row">
                    <div className="stepwizard-step">
                        <a href={`${APP_URL}book?step=1`}
                            className={finishedSteps[0] ? "btn btn-success btn-circle text-white" : "btn btn-primary btn-circle text-white"}
                        >1</a>
                      <strong>  <p>Pickup/Drop</p></strong>
                    </div>
                    <div className="stepwizard-step">
                        <a href={`${APP_URL}book?step=2`}
                            className={finishedSteps[1] ? "btn btn-success btn-circle text-white" : "btn btn-primary btn-circle text-white"}
                        >2</a>
                       <strong> <p>Cars</p></strong>
                    </div>
                    <div className="stepwizard-step">
                        <a href={`${APP_URL}book?step=3`}
                            className={finishedSteps[2] ? "btn btn-success btn-circle text-white" : "btn btn-primary btn-circle text-white"}
                        >3</a>                    <strong>   <p>Extra</p> </strong>
                    </div>
                    <div className="stepwizard-step">
                        <a href={`${APP_URL}book?step=4`}
                            className={finishedSteps[3] ? "btn btn-success btn-circle text-white" : "btn btn-primary btn-circle text-white"}
                        >4</a>                      <strong>  <p>Infomations</p></strong>
                    </div>
                    <div className="stepwizard-step">
                        <a href={`${APP_URL}book?step=5`}
                            className={finishedSteps[4] ? "btn btn-success btn-circle text-white" : "btn btn-primary btn-circle text-white"}
                        >5</a>                     <strong>   <p>Confirmation</p></strong>
                    </div>
                </div>
            </div>

        </div>
    )
}