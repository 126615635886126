import React from 'react'
import banner from "../images/banner_1.jpeg"
export default function Services() {
    return ( 
    <div>
    <section className="hero-wrap hero-wrap-2 js-fullheight" style={{ "background-image": `url(${banner})` }}  data-stellar-background-ratio="0.5">
      <div className="overlay"></div>
      <div className="container">
        <div className="row no-gutters slider-text js-fullheight align-items-end justify-content-start">
          <div className="col-md-9 ftco-animate pb-5">
          	<p className="breadcrumbs"><span className="mr-2"><a href="index.html">Home <i className="ion-ios-arrow-forward"></i></a></span> <span>Services <i className="ion-ios-arrow-forward"></i></span></p>
            <h1 className="mb-3 bread">Our Services</h1>
          </div>
        </div>
      </div>
    </section>

    <section className="ftco-section">
			<div className="container">
				<div className="row justify-content-center mb-5">
          <div className="col-md-7 text-center heading-section ftco-animate">
          	<span className="subheading">Services</span>
            <h2 className="mb-3">Our Latest Services</h2>
          </div>
        </div>
				<div className="row">
					<div className="col-md-3">
						<div className="services services-2 w-100 text-center">
            	<div className="icon d-flex align-items-center justify-content-center"><span className="flaticon-route"></span></div>
            	<div className="text w-100">
                <h3 className="heading mb-2">Online Reservation </h3>
                <p>You can reserve your car wherever you are in the world.</p>
              </div>
            </div>
					</div>
					<div className="col-md-3">
						<div className="services services-2 w-100 text-center">
            	<div className="icon d-flex align-items-center justify-content-center"><span className="flaticon-route"></span></div>
            	<div className="text w-100">
                <h3 className="heading mb-2">City Transfer</h3>
                <p>We can pick your favourite car to you, wherever you are.</p>
              </div>
            </div>
					</div>
					<div className="col-md-3">
						<div className="services services-2 w-100 text-center">
            	<div className="icon d-flex align-items-center justify-content-center"><span className="flaticon-route"></span></div>
            	<div className="text w-100">
                <h3 className="heading mb-2">Airport Transfer</h3>
                <p>We can wait you at the airport, and give you the keys of your choosen car.</p>
              </div>
            </div>
					</div>
					
				</div>
			</div>
		</section>
		
		{/* <section className="ftco-section ftco-intro" style={{"background-image": "url(images/bg_3.jpg)"}}>
			<div className="overlay"></div>
			<div className="container">
				<div className="row justify-content-end">
					<div className="col-md-6 heading-section heading-section-white ftco-animate">
            <h2 className="mb-3">Do You Want To Earn With Us? So Don't Be Late.</h2>
            <a href="#" className="btn btn-primary btn-lg">Become A Driver</a>
          </div>
				</div>
			</div>
		</section> */}

    
  
        </div>
    )
}