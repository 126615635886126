import React from 'react'
import banner from "../images/banner_4.jpeg"

export default function Contact() {
    return ( <div>
   <section className="hero-wrap hero-wrap-2 js-fullheight" style={{ "background-image": `url(${banner})` }} data-stellar-background-ratio="0.5">
      <div className="overlay"></div>
      <div className="container">
        <div className="row no-gutters slider-text js-fullheight align-items-end justify-content-start">
          <div className="col-md-9 ftco-animate pb-5">
          	<p className="breadcrumbs"><span className="mr-2"><a href="index.html">Home <i className="ion-ios-arrow-forward"></i></a></span> <span>Contact <i className="ion-ios-arrow-forward"></i></span></p>
            <h1 className="mb-3 bread">Contact Us</h1>
          </div>
        </div>
      </div>
    </section>

    <section className="ftco-section contact-section">
      <div className="container">
        <div className="row d-flex mb-5 contact-info">
        	<div className="col-md-4">
        		<div className="row mb-5">
		          <div className="col-md-12">
		          	<div className="border w-100 p-4 rounded mb-2 d-flex">
			          	<div className="icon mr-3">
			          		<span className="icon-map-o"></span>
			          	</div>
			            <p><span>Address:</span> IMM JASSIM N71 AVENUE MOHAMED 5 GUELIZ MARRAKECH</p>
			          </div>
		          </div>
		          <div className="col-md-12">
		          	<div className="border w-100 p-4 rounded mb-2 d-flex">
			          	<div className="icon mr-3">
			          		<span className="icon-mobile-phone"></span>
			          	</div>
			            <p><span>Phone:</span> <a href="tel://+212670422105">+212 670-422-105</a></p>
			          </div>
		          </div>
		          <div className="col-md-12">
		          	<div className="border w-100 p-4 rounded mb-2 d-flex">
			          	<div className="icon mr-3">
			          		<span className="icon-envelope-o"></span>
			          	</div>
			            <p><span>Email:</span> <a href="mailto:contact@exclusifcar.com">contact@exclusifcar.com</a></p>
			          </div>
		          </div>
		        </div>
          </div>
          <div className="col-md-8 block-9 mb-md-5">
            <form action="#" className="bg-light p-5 contact-form">
              <div className="form-group">
                <input type="text" className="form-control" placeholder="Your Name" />
              </div>
              <div className="form-group">
                <input type="text" className="form-control" placeholder="Your Email" />
              </div>
              <div className="form-group">
                <input type="text" className="form-control" placeholder="Subject" />
              </div>
              <div className="form-group">
                <textarea name="" id="" cols="30" rows="7" className="form-control" placeholder="Message"></textarea>
              </div>
              <div className="form-group">
                <input type="submit" value="Send Message" className="btn btn-primary py-3 px-5" />
              </div>
            </form>
          
          </div>
        </div>
        <div className="row justify-content-center">
        	<div className="col-md-12">
        	<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13587.459242283356!2d-8.02973906968343!3d31.637551670080814!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xdafee976f34770b%3A0x3385148f762db9d0!2s71%20Bd%20Mohamed%20VI%2C%20Marrakech%2040000!5e0!3m2!1sfr!2sma!4v1676378062624!5m2!1sfr!2sma" width="600" height="450" style={{"border":0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        	</div>
        </div>
      </div>
    </section>
	

        </div>
    )
}